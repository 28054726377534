import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IInvoiceAsset,
	IInvoiceTx,
	ISellAssetType,
	ISellTradeQueryResp,
	ISwapTx,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const tradesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSellTrades: builder.query<IApiResponsePaginated<ISellTradeQueryResp[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/trades/sells', params }),
			providesTags: ['GetSellTrades'],
		}),
		getUserSellTrades: builder.query<
			IApiResponsePaginated<ISellTradeQueryResp[]>,
			IApiPaginatedFilterQuery & { userId?: string }
		>({
			query: ({ userId, ...params }) => ({ url: `/admin/trades/sells/user/${userId}`, params }),
			providesTags: ['GetUserSellTrades'],
		}),
		getSingleSellTrade: builder.query<IApiResponsePaginated<ISellTradeQueryResp>, string | undefined>({
			query: (txReference) => ({ url: `/admin/trades/sells/sell/${txReference}` }),
			providesTags: ['GetSingleSellTrade'],
		}),
		getAllSellAssets: builder.query<IApiResponse<ISellAssetType[]>, { isActive: boolean } | void>({
			query: (params) => ({ url: '/admin/trades/sells/assets', params: params || {} }),
		}),
		resolveSellTrade: builder.mutation<IApiResponse, Partial<{ txReference: string; completed: boolean }>>({
			query: ({ completed, txReference }) => ({
				method: 'POST',
				url: `/admin/trades/sells/sell/${txReference}/resolve`,
				body: { completed },
			}),
			invalidatesTags: ['GetSellTrades', 'GetUserSellTrades', 'GetSingleSellTrade'],
		}),
		getInvoices: builder.query<IApiResponsePaginated<IInvoiceTx[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/trades/invoices', params }),
			providesTags: ['GetInvoices'],
		}),
		getUserInvoices: builder.query<IApiResponsePaginated<IInvoiceTx[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId }) => ({ url: `/admin/trades/invoices/user/${userId}` }),
			providesTags: ['GetUserInvoices'],
		}),
		getSingleInvoice: builder.query<IApiResponsePaginated<IInvoiceTx>, string | undefined>({
			query: (txReference) => ({ url: `/admin/trades/invoices/invoice/${txReference}` }),
			providesTags: ['GetSingleInvoice'],
		}),
		completeSingleInvoice: builder.mutation<IApiResponsePaginated, Record<string, boolean | string | undefined>>({
			query: ({ txReference, regenerate }) => ({
				method: 'PUT',
				url: `/admin/trades/invoices/invoice/${txReference}`,
				body: { regenerate },
			}),
			invalidatesTags: ['GetInvoices', 'GetUserInvoices', 'GetSingleInvoice', 'DashboardStats'],
		}),
		deleteSingleInvoice: builder.mutation<IApiResponsePaginated, string>({
			query: (txReference) => ({ method: 'PATCH', url: `/admin/trades/invoices/invoice/${txReference}` }),
			invalidatesTags: ['GetInvoices', 'GetUserInvoices', 'GetSingleInvoice', 'DashboardStats'],
		}),
		getAllInvoiceAssets: builder.query<IApiResponse<IInvoiceAsset[]>, void>({
			query: () => ({ url: '/admin/trades/invoices/assets' }),
		}),
		getAllSwaps: builder.query<IApiResponsePaginated<ISwapTx[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/trades/swaps',
				params,
			}),
		}),
		getUserSwaps: builder.query<IApiResponsePaginated<ISwapTx[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId, ...params }) => ({
				url: `/admin/trades/swaps/user/${userId}`,
				params,
			}),
		}),
		getSingleSwapTrade: builder.query<IApiResponsePaginated<ISwapTx>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/trades/swaps/swap/${txReference}`,
				providesTags: ['GetSingleSwapTrade'],
			}),
		}),
	}),
});

export const {
	useGetSellTradesQuery,
	useGetUserSellTradesQuery,
	useGetSingleSellTradeQuery,
	useGetAllSellAssetsQuery,
	useResolveSellTradeMutation,
	useGetInvoicesQuery,
	useGetUserInvoicesQuery,
	useGetSingleInvoiceQuery,
	useCompleteSingleInvoiceMutation,
	useDeleteSingleInvoiceMutation,
	useGetAllInvoiceAssetsQuery,
	useGetAllSwapsQuery,
	useGetUserSwapsQuery,
	useGetSingleSwapTradeQuery,
} = tradesApiSlice;
